<template>
<div>
   <a-carousel arrows :autoplay="true" :autoplaySpeed="5000" v-if="getBrowser == 'pc'" >
    <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px">
      <a-icon type="left-circle" />
    </div>
    <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
      <a-icon type="right-circle" />
    </div>
    <div v-for="(img, index) in source.images" :key="index">
      <img :src="img" />
    </div>
  </a-carousel>
  <van-swipe   class="picBox" v-if="getBrowser == 'phone'">
      <van-swipe-item >
        <img src="../../assets/banner1.png" alt="">
      </van-swipe-item>   
      <van-swipe-item >
        <img src="../../assets/banner2.png" alt="">
        
      </van-swipe-item>
      
    </van-swipe>
  </div>
 
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      required: true,
      validator: (value) => {
        /*
          首页Banner: 1,
          学习中心Banner: 6
         */
        return [1, 6].includes(value);
      },
    },
  },
  data() {
    return {
      source: {
        images: [],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { type } = this;
      let res = null;
      if (type === 1) {
        res = await this.api.service.fpc_oapi_photos_selectPublished({}, {});
      } else if (type === 6) {
        res = await this.api.service.fpc_oapi_photos_queryStudyBanner({}, {});
      }
      await this.api.toast(res, 0);
      this.source.images = res.data;
    },
  },
};
</script>
<style lang="less">
@media screen and (min-width: 320px) and (max-width: 1024px) {
   .picBox {
      width: 100%;
      height:400px;
      img{
          width: 100%;  
          height: 100%;  
         
      }
  }
  .custom-slick-arrow {
    display:none;
    i{
      display: none;
    }
  }
}
@media screen and (min-width: 1024px) {
  .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    opacity: 0.3;
    border-radius: 50%;
    z-index: 1;
    i {
      color: white;
    }
  }
}
.ant-carousel {
  .slick-slide {
    img {
      margin: 0 auto;
      width: 100%;
    }
  }

  .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    opacity: 0.3;
    border-radius: 50%;
    z-index: 1;
    i {
      color: white;
    }
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover {
    opacity: 0.5;
  }
  .slick-active button {
    background: @primary-color !important;
  }
}
</style>
